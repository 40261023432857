import React from "react"
import Layout from '../components/layout'
import { useStaticQuery } from 'gatsby'

import indexStyles from './index.module.scss'

export default () => {
    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    linkedin
                    twitter
                    }
                }
            }
        `)

    return(
        <Layout>
            <div>
                <h1>Hi. I'm Tim!</h1>
                <p>I create blazing-fast websites by utilizing the Jamstack architecture.</p>
                <p>You can reach me through<span> <a href={`https://linkedin.com/in/${data.site.siteMetadata.linkedin}`} rel="noopener noreferrer" target="_blank">LinkedIn</a> </span> 
                    or<span> <a href={`https://twitter.com/${data.site.siteMetadata.twitter}`} rel="noopener noreferrer" target="_blank">Twitter</a></span>.</p>
            </div>
        </Layout>
    )
}
